









































































































































































































































































import {
  computed,
  defineComponent,
  onMounted,
  provide,
  reactive,
  toRefs,
  watch
} from "@vue/composition-api";
import { getProductionDataList } from "@/api/energy-data/production_data";
import {
  deleteProducerData,
  getProducerData
} from "@/api/organizations/producer";
import { dynamicSort, seperateThousand } from "@/utils";
import { formattedAddress } from "@/helpers/formats";
import { rootInstance } from "@/helpers/composition";

import { Notification } from "element-ui";
import { cloneDeep } from "lodash";

import { IProductionData } from "@/lib/types/energy-data/production_data";
import { IProducerDetails } from "@/lib/types/organizations/producer";
import { OrganizationTypes } from "@/lib/types/base";
import { getProductionSitesList } from "@/api/energy-assets/production_site";

import AnimatedNumber from "animated-number-vue";

export default defineComponent({
  name: "Producer",
  components: {
    AggregatedChart: () => import("@/components/charts/AggregatedChart.vue"),
    ApexChart: () => import("@/components/charts/ApexChart.vue"),
    AggregatedDatalist: () =>
      import("@/components/datalists/AggregatedDatalist.vue"),
    PieChart: () => import("@/components/charts/PieChart.vue"),
    ProducerDetails: () => import("@/components/producer/ProducerDetails.vue"),
    ProducerCrudForm: () => import("@/components/forms/ProducerCrudForm.vue"),
    ProductionSitesList: () =>
      import("@/components/production-site/ProductionSitesList.vue"),
    AnimatedNumber,
  },
  setup() {
    const { root } = rootInstance();

    const roles = computed(() => root.$store.getters["user/roles"]);

    const state = reactive({
      details: {} as IProducerDetails,
      detailsInit: {} as IProducerDetails,
      detailsLoaded: false,
      productionData: {} as Partial<IProductionData>,
      producerDataLoaded: false,
      datalistVisible: false,
      editModalVisible: false,
      sitesCount: 0,
      activeTab: "",
      isRouterAlive: true,
      producerCrudFormValue: false,
      chartName: "Stacked Area Chart",
    });

    const producerDataFn = () => {
      state.detailsLoaded = false;
      state.producerDataLoaded = false;

      getProducerData(root.$route.params.id)
        .then(async res => {
          state.details = cloneDeep(res);
          state.detailsInit = Object.freeze(state.details);
          state.detailsLoaded = true;

          await getProductionDataList({
            producer: res.id,
            productionStartAfter: root.$store.state.period[0],
            productionStartBefore: root.$store.state.period[1],
            limit: 1,
            offset: 0,
          })
            .then(res => {
              state.productionData = res;
              state.producerDataLoaded = true;

              if (state.productionData.results) {
                state.productionData.results.sort(
                  dynamicSort("production_start")
                );
              }
            })
            .catch(err => {
              state.producerDataLoaded = false;
              console.error(err.body);
            });
        })
        .catch(err => {
          state.detailsLoaded = false;
          if (err.statusCode === 404) root.$router.push({ name: "NotFound" });
        });
    };

    producerDataFn();

    const closeEditModal = () => {
      state.editModalVisible = false;
      state.details = cloneDeep(state.detailsInit);
    };

    const deleteSite = () => {
      root.$confirm(
        /* Message */
        `
          Are you sure you want to delete <code class="bg-border text-dark-l2 rounded-sm px-1">${state.details.name}</code>?
          <br>
          This action can only be <span class="font-medium">reversed by a superadmin</span>.
        `,
        /* Title */
        root.$i18n.t("labels.warning") as string,
        {
          confirmButtonText: root.$i18n.t("labels.delete") as string,
          cancelButtonText: root.$i18n.t("labels.cancel") as string,
          type: "warning",
          center: true,
          showClose: false,
          dangerouslyUseHTMLString: true,

          beforeClose: async (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;

              await deleteProducerData(root.$route.params.id)
                .then(() => {
                  instance.confirmButtonLoading = false;

                  Notification({
                    title: "Operation successful",
                    message: `${state.details.name} deleted successfully`,
                    type: "success",
                    position: "bottom-left",
                  });

                  root.$router.push({ name: "ProducersList" });

                  done();
                })
                .catch(err => console.error(err.body));
              /*  */
            } else {
              done();
            }
          },
        }
      );
    };

    const siteUpdated = () => {
      producerDataFn();
      state.editModalVisible = false;
    };

    onMounted(async () => {
      const initialHash = window.location.hash.slice(1);
      state.activeTab = initialHash || "overview";

      getProductionSitesList({
        limit: 0,
        offset: 0,
        producer: root.$route.params.id,
      })
        .then(res => (state.sitesCount = res.count))
        .catch(err => alert(JSON.stringify(err.body.errors.detail)));

      watch(
        () => [root.$store.state.period, state.activeTab],
        (newVal, oldVal) => {
          root.$router
            .replace({
              query: Object.assign(
                {},
                {
                  start: root.$store.state.period[0].split("T")[0],
                  end: root.$store.state.period[1].split("T")[0],
                }
              ),
              hash: state.activeTab,
            })
            .catch(() => {});

          if (
            JSON.stringify(newVal[0]) !== JSON.stringify(oldVal && oldVal[0])
          ) {
            producerDataFn();
          }
        },
        { deep: true, immediate: true }
      );
    });

    /**
     * ! Getters
     */
    const totalAmountData = computed(() => {
      return {
        total_amount_produced: state.productionData.total_amount_produced,
        total_amount_matched: state.productionData.total_amount_matched,
        total_consumption_sites_matched:
          state.productionData.total_consumption_sites_matched,
      };
    });

    const hourlyAggregatedData = computed(() =>
      state.productionData.hourly_aggregated_data?.sort(dynamicSort("hour"))
    );
    const allHourlyData = computed(() =>
      state.productionData.hourly_aggregated_data_for_each_day?.sort(
        dynamicSort("date")
      )
    );

    provide(
      "productionData",
      computed(() => state.productionData)
    );
    const changeF = (newVal: any) => {
      state.producerCrudFormValue = newVal;
    };
    const closeDialog = () => {
      if (state.producerCrudFormValue) {
        root.$confirm(
          /* Message */
          root.$i18n.t("messages.sure_to_close") as string,
          /* Title */
          root.$i18n.t("labels.warning") as string,
          {
            confirmButtonText: "OK",
            cancelButtonText: root.$i18n.t("labels.cancel") as string,
            type: "warning",
            center: true,
            showClose: false,
            dangerouslyUseHTMLString: true,

            beforeClose: async (action, instance, done) => {
              if (action === "confirm") {
                instance.confirmButtonLoading = true;
                state.isRouterAlive = false;
                root.$nextTick(() => {
                  state.isRouterAlive = true;
                });
                state.editModalVisible = false;
                instance.confirmButtonLoading = false;
                done();
                /*  */
              } else {
                done();
              }
            },
          }
        );
      } else {
        state.isRouterAlive = false;
        root.$nextTick(() => {
          state.isRouterAlive = true;
        });
        state.editModalVisible = false;
        state.producerCrudFormValue = false;
      }
    };
    const chartChange = (e: any) => {
      state.chartName = e;
    };
    return {
      ...toRefs(state),
      roles,
      deleteSite,
      closeEditModal,
      siteUpdated,
      formattedAddress,
      totalAmountData,
      hourlyAggregatedData,
      allHourlyData,
      seperateThousand,
      OrganizationTypes,
      closeDialog,
      changeF,
      chartChange,
    };
  },
});
